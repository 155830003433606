@import '../../style/mixins';
@import '../../style/themes/default';

@resultPrefixCls: am-result;

.@{resultPrefixCls} {
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 30 * @hd;
  padding-bottom: @v-spacing-xl;
  background-color: @fill-base;
  .hairline('bottom');

  & &-pic {
    width: 60 * @hd;
    height: 60 * @hd;
    margin: 0 auto;
    line-height: 60 * @hd;
    background-size: 60 * @hd 60 * @hd;
  }

  & &-title,
  & &-message {
    font-size: 21 * @hd;
    color: @color-text-base;
    padding-left: @h-spacing-lg;
    padding-right: @h-spacing-lg;
  }

  & &-title {
    margin-top: @v-spacing-lg;
    line-height: @line-height-base;
  }

  & &-message {
    margin-top: @v-spacing-md;
    line-height: @line-height-paragraph;
    font-size: @font-size-caption;
    color: @color-text-caption;
  }

  & &-button {
    padding: 0 @h-spacing-lg;
    margin-top: @v-spacing-lg;
  }
}

@brand-primary: #ed715a;@brand-primary-tap: #e46048;@h-spacing-md: 12 * @hd;